<template>
  <div class="row justify-content-center" v-loading="loading">
    <div class="col">
      <el-form :model="model" :show-message="false" ref="form">
        <div class="row">
            <div class="col-12">
                <small class="text-muted pl-1">公司名称</small>
                <el-form-item prop="company_name" :rules="[{required:true, message:'请填写公司名称'}]">
                    <el-input v-model="model.company_name" placeholder="公司名称"></el-input>
                </el-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <small class="text-muted pl-1">企业性质</small>
                <el-form-item prop="org_type" :rules="[{required:true, message:'请选择企业性质'}]">
                    <el-select v-model="model.org_type" placeholder="选择企业性质">
                    <el-option v-for="item in orgs" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <div class="row" v-if="model.org_type === city_org">
            <div class="col-12">
                <small class="text-muted pl-1">所属国企单位</small>
                <el-form-item prop="org_name" :rules="[{required:true, message:'请选择国企单位'}]">
                    <el-select v-model="model.org_name" placeholder="选择国企单位">
                    <el-option v-for="item in orgNames" :key="item" :label="item" :value="item">
                    </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <div class="row" v-else-if="model.org_type === ditrict_org">
            <div class="col-12">
                <small class="text-muted pl-1">所属地区</small>
                <el-form-item prop="org_district">
                    <el-select v-model="model.org_district" placeholder="选择地区">
                    <el-option v-for="item in orgDistricts" :key="item" :label="item" :value="item">
                    </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <div class="row" v-if="model.org_type !== city_org">
            <div class="col-12">
                <small class="text-muted pl-1">所属国企单位</small>
                <el-form-item prop="org_name" :rules="[{required:true, message:'请填写所属国企单位'}]">
                    <el-input v-model="model.org_name" placeholder="所属国企单位" required></el-input>
                    <small class="text-muted"><em>* 请填所属国企的政府部门登记核准名称的全称</em></small>
                </el-form-item>
            </div>
        </div>
        
        <div class="row">
            <div class="col-5 pr-1">
                <small class="text-muted pl-1">联系人</small>
                <el-form-item prop="contact_name">
                    <el-input v-model="model.contact_name" placeholder="联系人"></el-input>
                </el-form-item>
            </div>
            <div class="col-7 pl-1">
                <small class="text-muted pl-1">联系电话</small>
                <el-form-item prop="contact_phone">
                    <el-input type="number" v-model="model.contact_phone" placeholder="联系电话"></el-input>
                </el-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <small class="text-muted pl-1">联系邮箱地址</small>
                <el-form-item prop="contact_email">
                    <el-input v-model="model.contact_email" placeholder="联系邮箱地址" required></el-input>
                </el-form-item>
            </div>
        </div>
      </el-form>
        <div class="row">
            <div class="col-12">
                <h5 class="text-muted">*提交修改后，统计中心工作人员将在1个工作日内对注册信息进行审核。</h5>
            </div>
        </div>
      <div class="row mt-3">
        <div class="col text-right">
          <a href="javascript:;" class="px-3 text-default" @click="cancelled">&lt; 取消</a>
          <el-button type="success" class="px-3" @click="submitForm">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'account-edit-form',
    data () {
      return {
        loading: false,
        model: {
          company_name: '',
          org_type: '',
          org_name: '',
          org_district: '',
          contact_name: '',
          contact_phone: '',
          contact_email: ''
        },
        city_org: '市属国有企业',
        ditrict_org: '区属国有企业',
        orgs: []
      }
    },
    computed: {
      orgNames () {
        const cityOrg = this.orgs.find(x => x.name === this.city_org)
        if (cityOrg) return cityOrg.companies
        return []
      },
      orgDistricts () {
        const districtOrg = this.orgs.find(x => x.name === this.ditrict_org)
        if (districtOrg) return districtOrg.districts
        return []
      }
    },
    methods: {
      fetchData () {
        this.loading = true
        this.axios.get('orgupdate/0/edit').then((rep) => {
          if(rep.data.errcode === 0) {
            this.model = rep.data.data
          } else {
            this.$notifyErr('加载数据失败', rep.data.errmsg)
            this.$emit('cancelled', true)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$notifyErr('加载数据失败', e.message)
          this.$emit('cancelled', true)
        })
      },
      cancelled () {
        this.$emit('cancelled', true)
      },
      submitForm () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.save()
          } else {
            return false
          }
        })
      },
      save () {
        this.$emit('update:saving', true)
        this.loading = true
        this.axios.put('orgupdate/' + this.model.id, this.model).then((rep) => {
          if(rep.data.errcode === 0) {
            this.$emit('saved')
          } else {
            this.$notifyErr('提交数据失败', rep.data.errmsg)
          }
          this.$emit('update:saving', false)
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$notifyErr('提交数据失败', e.message)
          this.$emit('update:saving', false)
        })
      }
    },
    mounted () {
      this.axios.get('orglib').then((res) => {
        this.orgs = res.data
      })
      this.fetchData()
    }
  }
</script>
<style scoped>
  .el-form-item.is-error .el-radio-group {
    border-bottom: 3px solid #da4a16;
  }
</style>